.app-container {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.model-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
}

.model-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.model-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

h3 {
    font-size: 18px;
    margin: 10px 0;
    color: #333;
}

.social-links {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
}

.social-link {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #007bff;
    border-radius: 5px;
}

.social-link:hover {
    background-color: #007bff;
    color: white;
}
